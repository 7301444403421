<template>
  <div>
    <v-card-text>
      <div id="siteCode" class="px-5 py-3 border rounded">
        <h5>{{ $t("siteCode") }}</h5>
        <text-input v-model="site.siteCode" label="Ex: 001"></text-input>
      </div>
    </v-card-text>
    <v-card-text>
      <div id="name" class="px-5 py-3 border rounded">
        <h5>{{ $t("name") }}</h5>
        <text-input v-model="site.name" label="Ex: Centre de test"></text-input>
      </div>
    </v-card-text>
    <v-card-text>
      <div id="address" class="px-5 py-3 border rounded">
        <h5>{{ $t("address") }}</h5>
        <text-input
          v-model="site.address"
          label="Ex: 26, rue Desaix - 75727 Paris"
        ></text-input>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <div class="my-3 text-right">
      <action-panel @action="onAction($event)"></action-panel>
    </div>
  </div>
</template>

<script>
import ActionPanel from "../admin/ActionPanelAdmin";
import TextInput from "../input/TextInput";
import { Site } from "../../../libs/spiral";

export default {
  data() {
    return {
      site: { name: undefined, siteCode: undefined, address: undefined }
    };
  },
  methods: {
    async onAction({ type }) {
      switch (type) {
        case "save":
          await this.createSite();
          this.goToSiteList();
          break;
        case "cancel":
          this.goToSiteList();
          break;
      }
    },
    async createSite() {
      const site = new Site(this.site.siteCode, {
        name: this.site.name,
        address: this.site.address
      });
      await this.drivers.siteDriver.save(this.currentStudy, site).catch(err => {
        Array.isArray(err)
          ? err.map(error => this.setError(error))
          : this.setError(err);
      });
    },
    goToSiteList() {
      this.$router.push({
        name: "Admin sites",
        query: {
          study: this.currentStudy.name
        }
      });
    }
  },
  i18n: {
    messages: {
      en: {
        name: "Name",
        siteCode: "Site Code",
        address: "Address"
      },
      fr: {
        name: "Nom du centre",
        siteCode: "N° centre",
        address: "Adresse"
      }
    }
  },
  components: {
    TextInput,
    ActionPanel
  }
};
</script>
