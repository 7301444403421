<template>
  <v-card>
    <v-card-title>
      <div class="pt-3 pl-2">{{ $t("newSite") }}</div>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      <site-inputs></site-inputs>
    </v-card-text>
  </v-card>
</template>

<script>
import SiteInputs from "../components/admin/SiteInputs.vue";
export default {
  components: { SiteInputs },
  i18n: {
    messages: {
      en: {
        newSite: "Create a new site"
      },
      fr: {
        newSite: "Créer un nouveau centre"
      }
    }
  }
};
</script>
